/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr';
@import './assets/css/variables';
@import "./assets/css/style.css";




.light-theme {
//   // background-color: $white;
//   header {
//     background-color: $black;
//   }
//   .dahsboardContent {
//     background-color: $white;
//   }
//   .boardSideNav {
//     background-color: $black;
//     color: $white;
//   }
//   .table{
//     th {
//       background-color: $primary-color;
//       color: $white;
//     }
    
//     i {
//       color: $primary-color;
//     }
//   }
//   // .boardSideNav li a {
//   //   border: 1px solid $black;
//   //   color: $white;
//   // }
//   .wallet {
//     background-color: $white;
//     color: $black;
//   }
//   h5, h6 {
//     color: $black;
//   }

//   .balanceBlockInr {
//     h6 {
//       color: $white;
//     }
//   }

}

.dark-theme {
//   background-color: $black;
//   header {
//     background-color: $white;
//   }
//   .dahsboardContent {
//     background-color: $black;
//   }
//   .boardSideNav {
//     background-color: $white;
//     color: $black
//   }
//   .table{
//     th {
//       background-color: $black;
//       color: $white;
//     }
//     td, i {
//       color: $white;
//     }
//     .sellVal, .buyVal {
//       color: $black;
//     }
//   }
//   .wallet {
//     background-color: $black;
//     color: $white
//   }
//   // .boardSideNav li a {
//   //   border: 1px solid $white;
//   // }
//   h5, h6 {
//     color: $white;
//   }
}

.error {
	color: #e1191d;
	font-size: small
}

.wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-left: 5px;
  }


.highlight-up,
.sellVal > div.highlight-up,
.buyVal > div.highlight-up {
    color: $white !important;
    background-color: $positive-value;
}

.highlight-down,
.sellVal > div.highlight-down,
.buyVal > div.highlight-down {
    color: $white !important;
    background-color: $negative-value;
}

.div-width {
    padding: 4px;
    border-radius: 3px;
}
.td-padding {
    padding-right: 60px !important;
}
.icons-color {
    color: $primary-color;
}
.cursor {
    cursor: pointer;
}
.grid-color {
    background: $white;
    /*min-height: 100%;*/
}
.tableOuter {
    overflow-x: auto;
}
.tab-content .card {
    width: 24.5% !important;
}
.pagination {
    margin-top: 25px;
}
.pagination .page-item.active .page-link {
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.pagination .page-link {
    color: #17a2b8;
    border: 1px solid #ffffff;
}
.pagination .page-item.disabled .page-link {
    border: 1px solid #ffffff;
}
.dahsboardContent .btn-outline-info:not(:disabled):not(.disabled).active:focus, .dahsboardContent .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dahsboardContent .show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: none;
}
.dahsboardContent app-order-book {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    padding-bottom: 100px;
}

.table-layout {
table-layout: fixed;
}

.w-5 {
    width: 5% !important;
  }

  .w-7 {
    width: 7% !important;
  }
  
  .w-10 {
    width: 10% !important;
  }

  .w-15 {
    width: 15% !important;
  }

  .w-20 {
    width: 20% !important;
  }

  .w-25 {
    width: 23% !important;
  }

  .status-circle-online {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $positive-value;
    border: 2px solid $white;
    bottom: 10px;
    right: 0;
    position: absolute;
  }

  .status-circle-offline {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(160, 157, 157);
    border: 2px solid $white;
    bottom: 10px;
    right: 0;
    position: absolute;
  }

  .icon-container {
    width: 50px;
    height: 50px;
    position: relative;
  }


  .dropdown-divider {
    margin: 0 !important;
    padding: 0 !important;
  }


  .dropdown-menu {
    padding: .2px !important;
    margin: 0 !important;
    border-radius: 0 !important;
    z-index: 999999 !important;
  }

  .dropdown-item:focus, .dropdown-item:hover {
    color: $white !important;
    text-decoration: none;
    background-color: $primary-color !important;   
}

.dropdown-item {
  padding: .5rem 1.5rem !important;
}

.fa-size {
  font-size: 19px !important;
}


.sellVal button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 10px !important;
  background-color: $negative-value;
}

.buyVal button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 10px !important;
    background-color: $positive-value;
}

button, input, textarea, select {
  box-shadow: none !important;
}

input {
  &:active {
    border-color: $primary-color;
  }
  &:focus {
    border-color: $primary-color;
  }
}


// portfolio plan css
.balanceDetail {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-bottom: 50px;
  // background: $white;
  padding-top: 15px;
  text-align: center;
  color: $white;
}
.balanceDetail .balanceBlock {
  display: inline-block;
  max-width: 510px;
  padding: 5px;
  color: $white;
  width: 33.3%;
  vertical-align: top;
}
.balanceDetail .balanceBlockInr {
  // background: #ffa500;
  // box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
  background-color: $primary-color;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
  border-radius: 8px;
  width: 80%;
}

.balanceDetail .balanceBlock h2 {
  font-weight: normal;
  padding-bottom: 25px;
  margin-bottom: 35px;
  border-bottom: 1px solid $primary-color;
}

.balanceDetail .balanceBlock p {
  font-size: 25px;
}

.subscribeBlk {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
  background: $white;;
  padding-bottom: 25px;
}
.subscribeData {
width: 24%;
display: inline-block;
vertical-align: top;
  margin: 0 2px;
  &:hover{
    transform: scale(1.1);
    border-radius: 10px;
  }
}
.subscribeData 
{
  h2, h4 {
  background: linear-gradient(0deg, #49a8da, #72d1fc);
  color: #fff;
  font-weight: normal;
  padding: 14px 10px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 20px;
  margin: 0px;
}
}
.subscribeData h3 {
  background: linear-gradient(0deg, #45a4d6, #57b6e8);
  color: #fff;
  font-weight: 600;
  padding: 10px 20px 10px;;
  font-size: 25px;
}
.subscribeData h3 span {
  display: inline-block;
  vertical-align: top;
  font-size: 25px;
  font-weight: normal;
}
.subscribeData ul {
  list-style: none;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.subscribeData ul li {
  padding: 10px 10px;
  color: #364d84;
}
.subscribeData ul li:nth-child(odd) {
  background: #fafafa;;
}
.subscribeData ul li:nth-child(even) {
  background: #eeeeee;;
}


.heading {
      color: #141d67;
     font-size: 27px;
    font-weight: normal;
    margin-bottom: 15px;
}

.innerPage {
    .table{
      th {
        background-color: $primary-color;
        color: $white;
      }
    }
    .sectionHeading p {
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 50px;
      /* padding: 0 20%; */
  }
}

.heading-title h2 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.heading-title h5 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 25px;
}

.heading-title p {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 50px;
  padding: 0
}

.notification-menu {
    width: 400px;
    min-height: auto !important;
    max-height: 400px !important;
    position: absolute !important;
    top: 40px !important;
    // left: -270px !important;
    z-index: 9999999 !important;
    overflow-y: auto;

}


.chat-popup {
  background-color: #fff;
  width:300px;
  position: fixed;
  bottom: 0;;
  left: 1px;
  border: 3px solid #f1f1f1;
  z-index: 999999999;
}


.chat-heading {
  background-color: #2197c3; 
}


.input-box {
    background-color: #edf1f7;;
    padding: 0;
}

.btn-send {
  width: 100%;
}