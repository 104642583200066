@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/*font-family: 'Roboto', sans-serif;*/
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*font-family: 'Barlow', sans-serif;*/
@import url('bootstrap.min.css');
@import url('slick.css');
@import url('slick-theme.css');
@import url('../fonts/icomoon/style.css');

* {
    margin: 0;
    padding: 0;
}
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}
body {
    line-height: 1;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font: normal 100%/1.5 'num','Open Sans',ariel,sans-serif;
    overflow-x: hidden;
}
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}
nav ul {
    list-style:none;
}
blockquote, q {
    quotes:none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}
a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}
/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}
/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000;
    font-style:italic;
    font-weight:bold;
}
del {
    text-decoration: line-through;
}
abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}
table {
    border-collapse:collapse;
    border-spacing:0;
}
/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;  
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}
input, select {
    vertical-align:middle;
}
input:focus, select:focus, textarea:focus {
    outline: 0;
}
img {
    max-width: 100%;
}
html{
    height: 100%
}

/************** 22_June-2021 Css Start From Here ******************/
/************** 22_June-2021 Css Start From Here ******************/

.user-dashboard #header .rightContent {
    margin-left: auto;
    float: right;
    max-width: 320px;
    display: inline-block !important;
}


/******************** 22_June-2021 Css End Here ******************/
/******************** 22_June-2021 Css End Here ******************/
.user-dashboard .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1050 !important;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}
.table th {
    padding: 8px !important;
    font-size: 14px;                                                                                                                                                                                                                                        
    vertical-align: middle;
    color: #fff;
     background-color: #2197c3; 
}

.table td, body table td {
    padding: 2px !important;
    font-size: 13px;
    vertical-align: middle !important;
}

/* body .table th, body .table td, body table th, body table td, body .container .table th {
    padding: 2px !important;
    font-size: 14px;
    /* font-weight: 400;   */
    /* font-family: DINOT,'Open Sans',Arial;; */
    /* vertical-align: middle;
} */

.mainPage {
    background-image: url('../images/banner-bg.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 100%;
}
body {
    background-color: #fff;
    color: #141d67;
    font-size: 15px;
    line-height: 24px;
    min-height: 100%;
}
#header {
    padding: 22px 35px 0;
}
.innerPage #header,
.innerPage .innerBanner {
    background: #0e122c;
}
.themeBtn,
.fieldOuter input.themeBtn,
button.themeBtn,
a.themeBtn,
.contactForm .fieldOuter input.themeBtn {
    background-color: #2197c3;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    border-radius: 4px;
    padding: 14px 28px;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    text-transform: uppercase;
    border: 2px solid transparent;
    width: auto;
}
.themeBtn:hover,
.fieldOuter input.themeBtn:hover,
button.themeBtn:hover,
a.themeBtn:hover,
.contactForm .fieldOuter input.themeBtn:hover {
    background-color: #fff;
    color: #2197c3;
    text-decoration: none;
    border: 2px solid #2197c3;
}
.themeBtn + .themeBtn {
    margin-left: 18px;
}
.themeBtn.whiteBtn {
    background-color: #fff;
    color: #2197c3;
}
.themeBtn.whiteBtn:hover {
    background-color: #2197c3;
    color: #fff;
}
.themeBtn.strokeBtn {
    background-color: #0e122c;
    color: #fff;
    border: 1px solid #414459;
}
.themeBtn.strokeBtn:hover {
    background: #fff;
    color: #0e122c;
}
#fundAccount .fieldOuter input.themeBtn {
    width: auto;
    padding: 10px 60px;
    margin-top: 25px;
}
.navContent > div {
    display: inline-block;
    vertical-align: top;
}
.searchIcon {
    padding-right: 18px;
    border-right: 1px solid #272a42;
    margin-top: 11px;
}
.searchIcon img {
    width: 14px;
    height: auto;
}
.navigation ul {
    list-style: none;
}
.navigation ul li {
    display: inline-block;
    vertical-align: top;
    position: relative;
}
.navigation ul li:first-child a {
    padding-left: 18px;
}
.navigation ul li a {
    display: inline-block;
    vertical-align: top;
    padding: 10px 35px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}
.navigation ul li a:hover,
.navigation > ul ul.subMenu li a:hover {
    color: #2197c3;
    text-decoration: none;
}
.navigation ul li a img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 7px;
}
.navigation ul li .subMenu {
    position: absolute;
    left: 19px;
    top: 45px;
    background: #fff;
    width: 240px;
    text-align: left;
    padding: 30px;
    z-index: 9999;
    display: none;
}
.navigation ul li:hover .subMenu {
    display: block;
}
.navigation ul li .subMenu li {
    width: 100%;
}
.navigation ul li .subMenu li a {
    color: #0e122c;
    padding: 15px 0;
    width: 100%;
}
.navigation > ul li:first-child a {
    padding-left: 18px;
}
.navigation ul.subMenu li:first-child a {
    padding-top: 0 !important;
}
.navigation ul.subMenu li:last-child a {
    padding-bottom: 0;
}
.bannerOuter {
    padding: 100px 0 135px;
    color: #fff;
    position: relative;
}
.bannerOuter:before {
    position: absolute;
    left: 0;
    top: 82px;
    width: 225px;
    height: 172px;
    background: url(../images/design-addon.png);
    background-size: cover;
    content: "";
}
#fundAccount .fieldOuter input {
    width: 100%;
}
.bannerOuter h2 {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 14px;
    line-height: 52px;
}
.bannerOuter p {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 300;
    line-height: 24px;
    color: #99a0ac;
}
.bannerBtns a,
.InrbannerBtns a {
    vertical-align: middle;
}
.bannerBtns span,
.InrbannerBtns span {
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    margin: 0 20px;
}
.bannerScreenImg {
    position: relative;
}
.bannerScreenImg:before {
    position: absolute;
    left: 0;
    top: -45px;
    width: 165px;
    height: 175px;
    background: url(../images/design-addon.png);
    background-size: cover;
    content: "";
}
.investInBlock {
    background: #fff;
    text-align: center;
    padding: 55px 35px 30px;
    border-radius: 4px;
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
    color: #141d67;
}
.investInBlock h3 {
    font-size: 27px;
    font-weight: 400;
    margin-bottom: 20px;
}
.investInBlock p {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 35px;
    color: #141d67;
}
.stokeInvest {
    padding: 130px 0 148px;
}
.col-sm-5.stokeInfo {
    padding-left: 88px;
    padding-right: 100px;
}
.stokeInfo h2 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 20px;
}
.stokeInfo p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 27px;
}
.col-sm-4.afInr {
    position: relative;
    padding-left: 40px;
    padding-right: 35px;
    margin-bottom: 28px;
}
.afInr i.checkIcon {
    width: 27px;
    height: 27px;
    position: absolute;
    left: 0;
    top: 6px;
    background-repeat: no-repeat;
    background-size: 100%;
}
.trade {
    text-align: center;
    padding-bottom: 125px;
}
.assetFeatures {
    text-align: left;
}
.sectionHeading {
    text-align: center;
}
.sectionHeading h2 {
    font-size: 27px;
    font-weight: 400;
    margin-bottom: 25px;
}
.sectionHeading p {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 50px;
    padding: 0 20%;
}
.multiAssets {
    padding: 18px 0 68px;
    text-transform: uppercase;
}
.assetIcon {
    margin-bottom: 20px;
}
.assetIcon img {
    height: 85px;
    width: auto;
}
.multiAssets h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
}
.multiAssets p,
.assetFeatures p {
    font-size: 14px;
    font-weight: 300;
}
.assetFeatures h4 {
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 8px;
}
.trade .themeBtn {
    padding: 9px 28px;
    border-radius: 3px;
    margin-top: 22px;
}
.darkSection {
    padding: 38px 0;
    background-image: url(../images/dark-bg.jpg);
    background-size: cover;
    background-position: top center;
    color: #99a0ac;
    position: relative;
    font-weight: 300;
}
.darkSection:before {
    position: absolute;
    left: 0;
    top: 115px;
    width: 180px;
    height: 170px;
    background: url(../images/design-addon.png);
    background-size: cover;
    content: "";
}
.darkSection h2 {
    font-size: 27px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #fff;
}
.darkSection p {
    margin-bottom: 15px;
}
.listStyle {
    list-style: none;
}
.listStyle li {
    margin-bottom: 15px;
    background-image: url('../images/check-icon.png');
    background-repeat: no-repeat;
    background-size: 12px auto;
    background-position: left 5px;
    padding-left: 20px;
}
.darkSection .themeBtn {
    margin-top: 10px;
    padding: 10px 20px;
}
.modal {
    background: rgba(8, 11, 30, 0.8);
}
.modal-content {
    color: #9a9b9f;
}
.modal-header, .modal-footer {
    border: none;
    padding: 0;
    text-align: center;
}
.modal-header {
    padding: 55px 25px 0;
    flex-direction: column;
}
.modal-body {
    padding: 0 25px 55px;
}
.modal-header .close {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 25px;
    top: 25px;
    opacity: 1;
    color: #d1d1d1;
    font-size: 24px;
    font-weight: normal;
    line-height: 16px;
}
.modal-header .close:focus {
    outline: 0
}
.modal-title {
    color: #0e0e0e;
    font-size: 27px;
    font-weight: normal;
    margin-bottom: 4px;
}
.modal-title,
.modal-header p {
    width: 100%;
    display: block;
}
.modal-header p {
    line-height: 20px;
    margin-bottom: 28px;
}
.modal-header a {
    color: #2198c3;
}
.modal-body .formOuter, .modal-body .orSeprator, .modal-body .socialLogin,
.forgotPassword .formOuter {
    width: 300px;
    margin: auto;
}
.modal-body .orSeprator {
    margin: 35px auto;
    text-align: center;
    position: relative;
}
.orSeprator:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: #d1d1d1;
    height: 1px;
    width: 100%;
}
.orSeprator span {
    display: inline-block;
    vertical-align: top;
    background: #fff;
    padding: 0 40px;
    z-index: 99;
    position: relative;
}
.socialLogin a {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
}
.socialLogin a:last-child {
    margin-bottom: 0;
}
.socialLogin a img {
    display: block;
    vertical-align: top;
}

.fieldOuter {
    padding-bottom: 18px;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
}

.modal-open .fieldOuter.withIcon.input-group {
    overflow: initial;
}

.fieldOuter:last-child {
    margin-bottom: 0 !important;
}
input:focus,
select:focus,
textarea:focus {
    outline: 0;
}
.fieldOuter input,
.fieldOuter select,
.fieldOuter textarea {
    border: 1px solid #dfe3e6;
    border-radius: 6px;
    padding: 16px 20px;
    width: 100%;
    color: #9a9b9f;
    resize: none;
}
.fieldOuter textarea {
    min-height: 117px;
}
.withIcon input {
    padding-left: 78px;
}
.fieldOuter .inputIcon {
    position: absolute;
    top: 0;
    left: 0;
    height: 58px;
    width: 55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #dfe3e6;
    font-size: 14px;
    color: #d1d1d1;
}
.fieldOuter i.icon-eye {
    position: absolute;
    font-size: 14px;
    color: #d1d1d1; 
    right: 15px;
    cursor: pointer;
    top: 29%;
    font-size: 15px;
}
.fieldOuter i.icon-eye:hover {
    color: #9a9b9f;
}
.fieldOuter input[type="password"] {
    padding-right: 35px;
}
.fieldOuter.clicked .inputIcon {
    color: #fff;
    background: #2197c3;
}
.fieldOuter > label {
    display: block;
    vertical-align: top;
    width: 100%;
    font-size: 16px;
    margin-bottom: 7px;
}
.fieldOuter.doubleField select {
    margin-left: 15px;
}
#fundAccount .modal-body .formOuter {
    width: 395px;
}
.fieldOuter.doubleField.amountField input {
    width: 278px;
}
.fieldOuter.doubleField.amountField select {
    width: auto;
    color: #2198c3;
}
#fundAccount .fieldOuter {
    margin-bottom: 25px;
}
.fieldOuter.doubleField input {
    width: 48%;
    float: left;
}
.fieldOuter.doubleField input + input {
    margin-left: 4%;
}
.fieldOuter.cardOptions {
    margin-top: -15px;
    border-radius: 0;
}
.paymentCards select {
    background-image: url(../images/credit-card.png);
    background-repeat: no-repeat;
    background-position: 94% center;
    background-size: 22px;
}
.rememberMe input,
.rememberMe span {
    display: inline-block;
    vertical-align: middle;
}
.rememberMe span {
    margin-left: 6px;
}
.checkboxOuter {
  display: block;
  position: relative;
  padding-left: 31px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}
.checkboxOuter input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #dfe3e6;
  border-radius: 4px;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkboxOuter input:checked ~ .checkmark:after {
  display: block;
}
.checkboxOuter .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid #2197c3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.rfLinks {
    margin: 14px -15px;
}
.rfLinks a {
    color: #2198c3;
}
.rfLinks a.fotgotLink {
    color: #9a9b9f;
}
.exploreMarket {
    padding: 95px 0;
}
.col-sm-6.explrMrktInfo {
    padding-left: 95px;
    color: #000;
}
.explrMrktInfo h2 {
    font-size: 27px;
    margin-bottom: 22px;
}
.explrMrktInfo p,
.explrMrktInfo li {
    font-weight: 300;
}
.explrMrktInfo p {
    margin-bottom: 20px;
}
.explrMrktInfo .themeBtn {
    margin-top: 10px;
    padding: 10px 22px;
}
.testimonials {
    background-color: #f7f8fb;
    padding: 100px 0;
}
.testimonials {
    background-color: #f7f8fb;
}
.slick-slide > div {
    padding: 0 15px;
}
.testiCntn {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 15px;
    line-height: 27px;
    overflow: hidden;
}
.testiCntn:hover {
    cursor: pointer;
}
.quoteIcon {
    margin-bottom: 20px;
    text-align: left;
    padding: 35px 22px 0;
}
.quoteIcon i.icon-quote {
    font-size: 25px;
    transform: rotate(180deg);
    display: inline-block;
    vertical-align: top;
}
.testiCntn:hover .quoteIcon i.icon-quote {
    color: #2197c3;
}
.testiCntn p {
    padding: 0 22px;
    margin-bottom: 30px;
}
.testiUserInfo {
    padding: 0 22px 32px;
    background: #0e122c;
    color: #fff;
    position: relative;
    margin-top: 115px;
}
.testiUserInfo:before {
    position: absolute;
    content: "";
    left: 0;
    top: -45px;
    width: 100%;
    height: 48px;
    background-image: url('../images/testi-shape-dark.png');
    background-size: 100%;
    background-repeat: no-repeat;
}
.testiCntn:hover .testiUserInfo {
    background: #2197c3;
}
.testiCntn:hover .testiUserInfo:before {
    background-image: url('../images/testi-shape-light.png');
}
.testiuserImg {
    width: 105px;
    height: 105px;
    margin: auto auto;
    border-radius: 50%;
    border: 4px solid #fff;
    overflow: hidden;
    position: relative;
    z-index: 99;
    top: -87px;
}
.testiuserImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.testiUserName {
    margin-top: -64px;
    font-size: 17px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
}
.testiSocialLinks a {
    display: inline-block;
    vertical-align: top;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    margin: 0 9px;
}
.trusted {
    padding: 80px 0 100px;
}
.companyLogos {
    text-align: center;
}
.companyLogos a {
    display: inline-block;
    vertical-align: top;
    margin: 0 28px;
}
#footer {
    background: #0e122c;
}
#footer,
#footer a {
    color: #99a0ac;
    font-weight: 300;
    font-size: 14px;
}
#footer .widgeTitle {
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #64738b;
}
#footer ul {
    list-style: none;
    margin-bottom: 55px;
}
#footer ul li {
    margin-bottom: 10px;
}
#footer ul:last-child,
#footer ul li:last-child {
    margin-bottom: 0;
}
.footerLogo {
    margin-bottom: 22px;
}
.footerWidgets {
    padding: 80px 0 70px;
    border-bottom: 1px solid #272a42;
}
.termsText {
    padding-top: 80px;
    padding-bottom: 80px;
    line-height: 32px;
}
.termsText p {
    margin-bottom: 48px;
}
.termsText p:last-child {
    margin-bottom: 0;
}
.termsText a {
    padding: 0 6px;
}
.termsText a:first-child {
    padding-left: 0;
}
.termsText a + a {
    border-left: 1px solid #6f7582;
}
.gridIcon,
.plusIcon {
    border: 1px solid #252c3b;
    display: inline-block;
    vertical-align: top;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
}
.curencyValue {
    padding-top: 8px;
}
.gridIcon i {
    font-size: 30px;
}
.plusIcon i {
    font-size: 23px;
}
.gridIcon a,
.plusIcon a {
    padding: 8px;
    height: 100%;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    line-height: 34px;
    color: #515660;
}
.plusIcon a {
    line-height: 42px;
}
.valuePercentage {
    display: inline-block;
    vertical-align: middle;
    width: 130px;
    background: #343b4c;
    color: #fff;
    border-radius: 4px;
    border-bottom: 2px solid #099ad8;
    padding: 7px 6px 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    margin: 0 10px;
    position: relative;
}
.valuePercentage p span {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    color: #dd175a;
}
.valuePercentage i.icon-close {
    position: absolute;
    right: 6px;
    top: 7px;
    color: #747c8b;
    font-size: 10px;
    cursor: pointer;
}
.valuePercentage i.icon-close:hover {
    color: #bfbfbf;
}
.profileImg {
    height: 35px;
    width: 35px;
    overflow: hidden;
    background: #099ad8;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
}
.profileImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.rightContent > div {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
}
.rightContent .themeBtn {
    padding: 8px 32px;
}
.notifications {
    font-size: 25px;
    margin-left: 30px;
    cursor: pointer;
}
.user-dashboard #header .rightContent > div.curncyVal {
    margin-left: 0;
    text-align: left;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    color: #29c75a;
    width: auto;
    float: left;
}
.user-dashboard #header .rightContent .dropdown.dropleft {
    margin-top: 15px;
}
.curncyVal .userName {
    text-align: right;
    padding-right: 25px;
}
.curncyVal select {
    font-size: 24px;
    color: #29c75a;
    background-color: transparent;
    background-image: url(../images/select-arrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    border: none;
    padding: 0 22px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}
.estBal {
    color: #1f623e;
    padding-left: 7px;
}
.dashboard {
     background: #131722; 
}
.dashboard #header {
    padding: 4px 7px;
     background: #181f2c; 
    box-shadow: 0px 0px 10px 2px rgba(24, 31, 44, 0.8);
    -webkit-box-shadow: 0px 0px 10px 2px rgba(24, 31, 44, 0.8);
    /*overflow-x: hidden;*/
}
/* .col.boardSideNav {
    max-width: 80px;
    flex: none;
    min-height: 100%;
} */
.col.boardSideNav {
    max-width: 105px;
    flex: none;
    min-height: calc(100vh - 116px);
    border-right: 1px solid;
     background: #181f2c; 
}
.boardSideNav li a {
    color: #c5c8cc;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    /* padding: 12px 2px; */
    padding: 12px 10px;
    border-bottom: 1px solid #2b2f39;
}
.boardSideNav li a:hover {
    text-decoration: none;
}
.boardSideNav li a i {
    font-size: 22px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-bottom: 12px;
}
.boardSideNav li a:hover {
    color: #099ad8;
}
.switchBtn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 20px;
  margin: 0 5px;
}
.switchBtn input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.switchBtn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 1px;
  right: 0;
  bottom: 0;
  background-color: #0e122c;
  -webkit-transition: .4s;
  transition: .4s;
}
.switchBtn .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3.5px;
    background-color: #e72d01;
    -webkit-transition: .4s;
    transition: .4s;
}
.switchBtn input:checked + .slider {
  background-color: #fff;
}
.switchBtn input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.switchBtn input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}
.switchBtn .slider.round {
  border-radius: 34px;
}
.switchBtn .slider.round:before {
  border-radius: 50%;
}
.boardFooter {
    background: #3a4357;
}
.boardFooter > div,
.boardFooter > span,
.boardFooter .switchBtn > span,
.boardFooter label {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}
.switchBtnOutr > span {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
}
.contactBtn {
    background: #2e3646;
    padding: 12px 14px;
}
.switchBtnOutr {
    padding: 9px 17px;
}
.boardFooter > div i {
    margin-right: 9px;
    font-size: 16px;
    display: inline-block;
    color: #2bab3f;
    vertical-align: middle;
}
.col.boardBody {
    max-width: 80%;
    flex: none;
}
.col.bRightSidebar {
    max-width: 9%;
    flex: none;
    /* padding: 10px; */
}
.amount {
    background: #353c4f;
    color: #fff;
    padding: 4px 8px;
    border-radius: 2px;
    margin-bottom: 25px;
}
.amount span {
    display: block;
    color: #989ca6;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
}
.amount samp {
    color: #989ca6;
}
.usdQuantity {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    text-align: right;
    margin-bottom: 17px;
}
.usdQuantity span {
    color: #29c75a;
    font-size: 25px;
    font-weight: 500;
    margin-top: 5px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
}
.bRightSidebar .buy,
.bRightSidebar .sell {
    height: 110px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    flex-direction: column;
    text-transform: uppercase;
    border-radius: 4px;
    background: #2bab3f;
}
.bRightSidebar .sell {
    /* margin-top: 10px; */
    background: #de2f12;
}
.bRightSidebar .buy i,
.bRightSidebar .sell i {
    margin-bottom: 8px;
    font-size: 32px;
}
.forgotPassPage {
    background-color: #0e122c;
    height: 100vh;
    display: flex;
}
.forgotPassPage main {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.forgotPassPage .checkboxOuter {
    color: #fff;
}
.forgotPassword {
    background: #fff;
    padding: 55px 25px;
    width: 500px;
    border-radius: 4px;
    margin: auto;
}
.forgotPassword h4 {
    color: #141d67;
    font-size: 27px;
    font-weight: normal;
    margin-bottom: 28px;
    text-align: center;
}
.fieldOuter p.error {
    position: absolute;
    bottom: 2px;
    margin: 0;
    width: 100%;
}
.innerBanner {
    color: #fff;
    padding: 115px 0 25px;
    position: relative;
}
.innerBanner:after,
.innerBanner:before {
    content: "";
    position: absolute;
    top: 110px;
    left: 0;
    width: 225px;
    height: 172px;
    background-image: url(../images/design-addon.png);
    background-position: top center;
}
.innerBanner:before {
    top: 182px;
    left: inherit;
    right: 0;
    width: 122px;
    height: 172px;
}
.innerBanner h1 {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 25px;
}
.innerBanner p {
    padding: 0 215px;
    color: #99a0ac;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
}
.InrbannerBtns {
    margin-bottom: 110px;
}
.breadcrumbs a {
    font-size: 16px;
    color: #2197c3;
    font-weight: 300;
    padding: 0 7px;
    position: relative;
}
.breadcrumbs a:after {
    content: "";
    position: absolute;
    right: -3px;
    top: 3px;
    background: #99a0ac;
    height: 80%;
    width: 2px;
    transform: rotate(22deg);
    -webkit-transform: rotate(22deg);
}
.breadcrumbs a:last-child {
    color: #99a0ac;
    cursor: default;
    text-decoration: none;
    pointer-events: none;
}
.breadcrumbs a:last-child:after {
    display: none;
}
.innerPage .trade {
    padding: 80px 0 87px;
    border-top: 1px solid #e6e7e9;
    border-bottom: 1px solid #e6e7e9;
}
.aboutOuter {
    padding: 95px 0;
}
.headingText {
    margin-bottom: 30px;    
}
.aboutOuter .headingText h2,
.aboutCntnt h3 {
    color: #141d67;
    font-size: 27px;
    font-weight: normal;
    margin-bottom: 15px;
}
.aboutCntnt .headingText p {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0;
}
.aboutCntnt p {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 38px;
}
.aboutCntnt p:last-child {
    margin-bottom: 0;
}
.aboutCntnt .col-sm-8 {
    padding-right: 270px;
}
.aboutSidebar {
    text-align: center;
}
.openAcc {
    background: #e4e5e7;
    padding: 50px 20px;
    margin-bottom: 40px;
}
.aboutSidebar h3 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 10px;
}
.aboutSidebar p {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 15px;
}
.aboutSidebar .themeBtn {
    padding: 10px 25px;
    font-size: 15px;
}
.aboutSidebar .loginLink {
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    margin: 20px 0 0;
}
.aboutSidebar .loginLink a {
    color: #2197c3;
}
.liveChat .themeBtn {
    padding: 10px 35px;
}
.aboutSidebar > div {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
}
.aboutCntnt {
    padding-right: 280px;
}
.contactUs .innerBanner {
    padding-bottom: 215px;
}
.contactCntnt .row {
    margin-top: -180px;
    background: #fff;
    z-index: 999;
    position: relative;
    border-radius: 7px;
    box-shadow: 0px 0 20px 0px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 0px 0 20px 0px rgb(0 0 0 / 30%);
}
.contactCntnt .contactForm {
    padding: 68px 78px;
}
.col-sm-5.contactInfo {
    background-color: #2197c3;
    padding: 65px 48px 116px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: #fff;
    background-image: url(../images/address-bg.png);
    background-repeat: no-repeat;
    background-position: right bottom;
}
.contactInfo h2 {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 58px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.contactInfo li {
    font-size: 16px;
    font-weight: 500;
    padding-left: 42px;
    list-style: none;
    position: relative;
}
.contactInfo li:first-child i {
    top: 30px;
}
.contactInfo li:last-child {
    margin-bottom: 30px;
}
.contactInfo li a {
    color: #fff;
}
.contactInfo li i {
    position: absolute;
    left: 0;
    font-size: 18px;
    top: 28px;
}
.contactInfo li span {
    display: inline-block;
    vertical-align: top;
    padding: 25px 0;
    width: 100%;
    border-bottom: 1px solid #6ab9d7;
    border-top: 1px solid #1e88af;
}
.contactInfo li:first-child span {
    border-top: none;
}
.contactInfo .socialLinks {
    padding-left: 42px;
}
.contactInfo .socialLinks a {
    color: #fff;
    font-size: 17px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
    border-right: 1px solid #38a2c9;
}
.contactInfo .socialLinks a:first-child {
    padding-left: 0;
}
.contactInfo .socialLinks a:last-child {
    border: 0;
}
.contactInfo .socialLinks a:hover {
    text-decoration: none;
    opacity: 0.7;
}
.contactInfo li.phone span {
    font-size: 21px;
}
.contactForm .fieldOuter {
    border-radius: 0;
}
.contactForm .fieldOuter input,
.contactForm .fieldOuter textarea {
    background: #f3f8fb;
    font-size: 14px;
}
.contactForm .fieldOuter input.themeBtn {
    width: 140px;
}
.contactForm .fieldOuter input:focus,
.contactForm .fieldOuter textarea:focus {
    border-color: #cccfe2;
}
.boardSideNav ul {
    list-style: none;
}
.dahsboardContent {
    overflow-x: hidden;
     background: #f0f8ff;
}
/*body .container {
    max-width: 99.2%;
}*/
.modal-open .modal-content .container {
    margin-left: auto;
}
.restaurant-detailed-ratings-and-reviews {
    margin-top: 70px;
}
.navigation .mobileMenu,
.navigation .closeMenu .fa-close {
    display: none;
}
.closeMenu {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 25px;
    font-weight: normal;
}
.sellVal button,
.sellVal > div,
.buyVal button,
.buyVal > div {
    float: left;
    height: 35px;
}
.sellVal button,
.buyVal button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 10px !important;
}
/*.sellVal button:hover,
.buyVal button:hover {
    background: #2197c3;
    border-color: transparent;
    color: #fff;
}*/
.sellVal > div,
.buyVal > div {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 120px;
    background: #fff; 
    padding: 8px;
    text-align: right;
    font-weight: 500;
}
.tradeHistory h6 {
    padding-top: 25px;
}
#header .rightContent {
    margin-left: auto;
}
.tableOuter table {
    width: 100%;
}
.bottomNumbers {
    /* position: absolute; */
    position: relative;
    bottom: 0;
    width: 100%;
    left: 0;
}

/*************** 8-june-2021 Css start from here ***********/
.user-dashboard .wallet[_ngcontent-mae-c135] {
    border-top: 2px solid #2197c3;
    background-color: #fff;
    position: fixed !important;
    width: 100% !important;;
    max-width: 94.5% !important;;
    right: 0 !important;;
    left: auto !important;;
}
.main_home_section .navbar.navbar-expand-lg.navbar-light {
    width: 100%;
    padding: 10px 10px;
    background-color: #0e122c;
}
.main_home_section .navbar-brand {
    display: inline-block;
    width: 100%;
}
.main_home_section .form-inline {
    display: inline-block !important;
    width: 100%;
}
.main_home_section .navbar-nav {
    display: inline-block !important;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: auto;
    margin: 7px 0px;
    max-width: 100% !impoetant;
}
.main_home_section .both-buttons {
    width: 26%;
    float: right;
}
.main_home_section .navbar-light .navbar-nav .nav-link {
    color: #fff !important;
}
.main_home_section .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #2197c3 !important;
}
.main_home_section .both-buttons .get-started.my-2 {
    float: left;
    width: auto;
    margin: 0px 23px !important;
    border: none;
    background-color: #fff;
    color: #2197c3;
    text-transform: uppercase;
    font-weight: bold;
    padding: 14px 20px;
    border: 1px solid #fff;
}
.main_home_section .both-buttons .login-user.my-2 {
    float: left;
    width: auto;
    margin: 0px 0px 0px 0px !important;
    background-color: #2197c3;
    border: none;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    padding: 14px 30px;
    border: 1px solid #2197c3;
}
.main_home_section .form-inline {
    display: inline-block !important;
    width: 100%;
}
.main_home_section .both-buttons .get-started:hover {
    background-color: #2197c3;
    color: #fff;
}
.main_home_section .both-buttons .login-user:hover {
    background-color: #fff;
    color: #2197c3;
}
.main_home_section .dropdown, .main_home_section .dropleft, .main_home_section .dropright, .main_home_section .dropup {
    position: relative;
    width: auto;
    float: left;
    padding: 0px 12px;
}
.main_home_section .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 13rem !important;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
}
.main_home_section .navbar-toggler-icon {
    display: inline-block;
    width: 2em !important;
    height: 2rem !important;
    vertical-align: middle;
    content: "";
    background: 50%/100% 100% no-repeat;
    background-color: #fff !important;
}
.main_home_section .navbar-light .navbar-nav .nav-link {
    color: red;
    text-transform: uppercase;
}
.main_home_section .btn-outline-success {
    color: #28a745;
    border-color: #28a745;
    float: right;
    margin: 6px 22px;
}

/*************** 8-june-2021 Css start from here ***********/


/*************** Main Admin Pages Css start from here ***********/
/*************** Main Admin Pages Css start from here ***********/

.admin-dashboard .circle-offline[_ngcontent-ssc-c143] {
    background: #a09d9d;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    margin-left: -20px !important;
    border: 2px solid #fff;
    margin-top: 5px !important;
}
.admin-dashboard .circle-online[_ngcontent-ssc-c143] {
    background: #37a000;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    margin-left: -20px !important;
    border: 2px solid #fff;
    margin-top: 5px !important;
}


/*************** Main Admin Pages Css start End here ***********/
/*************** Main Admin Pages Css start End here ***********/

/*media start*/
@media (min-width: 1200px) {
.bannerOuter .container,
.investFields .container,
.stokeInvest .container {
    max-width: 1335px;
}

}
@media (max-width: 1360px) {
#header .col-sm-3 {
    flex: none;
    width: auto;
}
#header .col-sm-9 {
    flex: none;
    max-width: 83%;
    margin-left: auto;
}
.navigation ul li a {
    padding: 10px 15px;
    font-size: 14px;
}
.bannerOuter {
    padding: 120px 0 85px;
}
.themeBtn,
.fieldOuter input.themeBtn,
button.themeBtn,
a.themeBtn,
.contactForm .fieldOuter input.themeBtn {
    font-size: 12px;
    padding: 10px 18px;
}
.bannerOuter h2 {
    font-size: 33px;
    line-height: 45px;
}
.bannerOuter .col-sm-5 {
    padding-left: 85px;
}
.investInBlock {
    padding: 35px 35px 30px;
}
.stokeInvest {
    padding: 100px 0 108px;
}
.col-sm-5.stokeInfo {
    padding-right: 50px;
}

}
@media (max-width: 1200px) {
#header .col-sm-3 {
    width: 145px;
}
.searchIcon {
    padding-right: 0;
}
.bannerOuter .col-sm-5 {
    padding-left: 65px;
}
.investFields .container {
    padding: 0 140px;
}
.investFields .col-sm-3 {
    flex: none;
    max-width: 48%;
    margin: auto auto 40px;
}
.stokeInvest .col-sm-7 {
    flex: none;
    max-width: 40%;
}
.stokeInvest .col-sm-5 {
    flex: none;
    max-width: 60%;
}
.col-sm-5.stokeInfo {
    padding-left: 30px;
}
.navContent > div.searchIcon {
    display: none;
}
.dashboard.user-dashboard .card .col.p-0.grid-color {
    width: 89% !important;
    float: left;
}
.main_home_section .navbar-nav li {
    padding-right: 20px !important;
}

}
@media (max-width: 992px) {
#header {
    padding: 22px 0 0;
}
.navigation ul {
    display: none;
}
.navigation ul.show {
    display: inline-block;
    vertical-align: top;
}
.navigation .mobileMenu {
    display: inline-block;
    vertical-align: top;
    color: #fff;
    font-size: 24px;
    margin-top: 3px;
    margin-right: 20px;
}
.navigation.open .closeMenu .fa-close {
    display: inline-block;
    vertical-align: top;
}
#header .col-sm-9 {
    max-width: 80%;
}
.bannerOuter h2 {
    font-size: 27px;
    line-height: 33px;
}
.bannerOuter .col-sm-5 {
    padding-left: 0;
}
.navigation.open {
    position: relative;
}
.navigation.open .mainMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #212529;
    z-index: 9999;
}
.bannerOuter:before {
    display: none;
}
.bannerScreenImg:before {
    width: 115px;
    height: 105px;
}
.bannerOuter {
    padding: 90px 0 65px;
}
.investFields .container {
    padding: 0 20px;
}
.stokeInvest {
    padding: 50px 0 88px;
}
.sectionHeading h2 {
    font-size: 25px;
    margin-bottom: 15px;
}
.sectionHeading p {
    padding: 0 5%;
}
.assetIcon img {
    height: 55px;
}
.multiAssets h3 {
    font-size: 17px;
    margin-bottom: 6px;
}
.multiAssets {
    padding: 18px 0 28px;
}
.trade .themeBtn {
    margin-top: 0;
}
.trade {        
    padding-bottom: 95px;
}
.col-sm-6.explrMrktInfo {
    padding-left: 15px;
}
.exploreMarket {
    padding: 55px 0;
}
.testimonials {
    padding: 50px 0;
}
.companyLogos a {
    margin: 0 22px 17px;
}
.companyLogos a img {
    height: 40px;
    width: auto;
}
.trusted {
    padding: 60px 0 50px;
}

/*************** 7-june-2021 Css start from here ***********/

.slick-prev::before, .slick-next::before {
    line-height: 35px;
}
.slick-next {
    right: -35px;
}
.slick-prev {
    left: -35px;
}
.slick-prev, .slick-next {
    width: 35px;
    height: 35px;
}
.dashboard.user-dashboard .card {
    border: 1px solid #2197c3;
    width: 19.5% !important;
    height: 100% !important;
    min-height: 280px !important;
}
.dahsboardContent .ng-star-inserted {
    padding: 10px;
}
.dashboard.user-dashboard .card {
    border: 1px solid #2197c3;
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    margin-top: 10px;
}
.admin-dashboard .dahsboardContent .ng-star-inserted {
    padding: 10px;
    display: inline-block;
    width: 100%;
    margin: 0 auto;
}
.admin-dashboard .dahsboardContent .ng-star-inserted .col {
    margin-bottom: 20px;
}
#navbarSupportedContent .headerBtns {
    float: left;
    width: 30%;
}
#navbarSupportedContent .navbar-nav.ml-auto {
    float: left;
    width: 70%;
}

/*************** 7-june-2021 Css End here ***********/

}

@media (max-width: 767px) {

/*************** 7-june-2021 Css start from here ***********/
.dahsboardContent .pagination li {
    padding: 5px 0px !important;
    display: inline-block !important;
}
.pagination {
    display: inline-block !important;
    padding-left: 0 !important;
    list-style: none;
    border-radius: .25rem;
    width: 100% !important;
}
.user-dashboard .search-terms {
    width: 96% !important;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
}
.user-dashboard .grid-color {
    background: #fff;
    /* min-height: 100%; */
    width: 100%;
    float: left;
}
.user-dashboard .grid-color .search-terms {
    margin-top: 5px;
}
#navbarSupportedContent .headerBtns, #navbarSupportedContent .headerBtns {
    float: left;
    width: 100%;
}
.rightContent .dropdown.dropleft {
    z-index: 1;
}
#pills-tabContent .form-row {
    display: inline-block;
}
#pills-tabContent .input-group-append {
    margin-left: -8px;
}
#pills-tabContent .form-row .col {
    flex-basis: 1;
}
.input-group-append .btn {
    line-height: 2.8;
}
.user-dashboard .dropleft .dropdown-m.show {
    top: 0px;
    left: auto;
    margin: 0px !important;
    width: 100%;
    position: relative;
    right: 0;
}
.navbar-dark .navbar-toggler {
    border: none;
}
.main_home_section .navbar-toggler-icon {
    background-color: #2197c3 !important;
}
.main_home_section .navbar-nav {
    background-color: #2197c3;
}

.dashboard.user-dashboard .balanceDetail .balanceBlockInr {
    width: 90%;
}
.dashboard.user-dashboard .balanceDetail.col-2.ng-star-inserted {
    display: inline-block !important;
    max-width: 100% !important;
    flex: 100% !important;
}
.dashboard.user-dashboard .col-10.ng-star-inserted {
    width: 100% !important;
    display: inline-block !important;
    max-width: 100% !important;
    flex: 100%;
}
.dashboard.user-dashboard #pills-tabContent .d-flex.py-3.justify-content-between .card.bg-light.ng-star-inserted {
    width: 100% !important;
    display: inline-block !important;
    margin-bottom: 10px;
}
.dashboard.user-dashboard #pills-tabContent .d-flex.py-3.justify-content-between {
    width: 100%;
    display: inline-block !important;
}
.dashboard.user-dashboard .row.py-3 {
    width: 100%;
    display: inline-block;
}
.dashboard.user-dashboard .nav a {
    margin-bottom: 10px;
}
.dashboard.user-dashboard .col.p-0.d-flex.justify-content-around {
    display: inline-block !important;
}
.dashboard.user-dashboard .col.p-0.d-flex.justify-content-around small {
    width: 50% !important;
    display: inline-block;
    padding: 0px !important;
}
.dashboard.user-dashboard .col.p-0.d-flex.justify-content-around {
    display: inline-block !important;
    width: 100%;
    position: absolute;
    top: 100%;
    background-color: #fff;
    left: 0;
    right: 0;
}
.dashboard.user-dashboard .col.p-0.d-flex.justify-content-around small {
    width: 50% !important;
    display: inline-block;
    padding: 0px !important;
}
.user-dashboard .dahsboardContent .col.ng-star-inserted .col-12 .chart[_ngcontent-fwr-c140] {
    width: 100%;
    height: 450px;
}
.user-dashboard .dahsboardContent .col-12.col-sm-12.col-md-12.col-lg-12 {
    padding: 0px;
    width: 100%;
    float: left;
}
.user-dashboard .dahsboardContent #technical-analysis {
    width: 100%;
}
.admin-dashboard #navbarNav .navbar-nav li .ng-star-inserted .form-inline.ng-untouched.ng-pristine.ng-valid .form-group {
    width: 100% !important;
    margin-left: 0px !important;
}
.admin-dashboard #navbarNav .navbar-nav li .ng-star-inserted {
    margin: 10px 0px 0px !important;
    float: left;
    width: 100%;
}
.admin-dashboard #navbarNav .navbar-nav li {
    margin: 0px !important;
}
.admin-dashboard #navbarNav .navbar-nav li .nav-link input {
    margin: 0px !important;
}
.admin-dashboard #navbarNav .navbar-nav li .nav-link {
    padding-bottom: 0px;
}
.aboutSidebar .openAcc {
    margin-top: 20px;
}
.admin-dashboard #navbarNav .navbar-nav li .ng-star-inserted .form-inline.ng-untouched.ng-pristine.ng-valid .form-group.hidden {
    width: 100%;
    float: left;
    margin: 0px 0px 10px auto;
}
.main_home_section .dropdown, .main_home_section .dropleft, .main_home_section .dropright, .main_home_section .dropup {
    width: 100%;
}
.user-dashboard #header .rightContent {
    max-width: 100%;
    display: inline-block !important;
    margin: 0 auto !important;
}
.col.boardSideNav {
    max-width: 100%;
    flex: none;
    min-height: calc(100vh - 116px);
    border-right: 1px solid;
    background: #181f2c;
    width: 100%;
}
.user-dashboard #header .rightContent .dropdown.dropleft {
    margin-top: 15px;
    width: auto;
    float: left;
}
.dropdown, .dropleft, .dropright, .dropup {
    width: 100%;
    text-align: left;
}
.both-buttons {
    width: 100%;
    float: left;
}
.both-buttons .get-started.my-2 {
    margin: 0px 15px !important;
}
.contactCntnt .contactForm {
    padding: 68px 20px;
}
.col-sm-5.contactInfo {
    padding: 65px 20px 116px;
}
.innerBanner p {
    padding: 0 0px;
}
.contactCntnt {
    padding-top: 200px;
}
.table-responsive .w-75 {
    width: 100% !important;
}
.subscribeData {
    width: 100% !important;
}
.modal-body .formOuter, .modal-body .orSeprator, .modal-body .socialLogin, .forgotPassword .formOuter {
    width: 100%;
    margin: auto;
}

/*************** 7-june-2021 Css End here ***********/
#header .col-sm-9 {
    max-width: 72%;
}
.bannerOuter {
    color: #0e122c;
    padding: 70px 0 65px;
    text-align: center;
}
.bannerOuter .col-sm-5,
.bannerOuter .col-sm-7 {
    flex: none;
    max-width: 100%;
}
.bannerOuter .row {
    flex-direction: column-reverse;
}
.investFields .container {
    padding: 0;
}
.investFields .col-sm-3 {
    max-width: 50%;
}
.stokeInvest {
    text-align: center;
}
.investInBlock {
    padding: 15px 15px 30px;
}
.col-sm-5.stokeInfo {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
}
.stokeInvest .col-sm-7 {
    max-width: 100%;
    margin-top: 45px;
}
.sectionHeading p {
    padding: 0;
}
.multiAssets .col-sm-3 {
    flex: none;
    max-width: 50%;
    margin-bottom: 25px;
}
.assetFeatures .col-sm-4.afInr {
    flex: none;
    max-width: 50%;
}
.darkSection .row {
    flex-direction: column-reverse;
}
.darkSection .col-sm-6 {
    max-width: 100%;
    flex: none;
}
.darkSection .col-sm-6:last-child {
    margin-bottom: 25px;
}
}
/*media end*/


.rightSide {
    width: calc(100% - 105px);
}